import moment from 'moment';

jQuery(document).ready(function ($) {

  // URL QUERY TO SECTION
  if (window.location.hash === '#tour') {
    e.preventDefault();

    var aid = $(this).attr("#tour");

    $('html,body').animate({
      scrollTop: $(aid).offset().top
    },'slow');
  }

  // Mobile menu toggle
  $('.menu-toggle').click(function () {
    $('nav').slideToggle();
    $(this).toggleClass('active');
  });

  // Form
  $("#morgan-wade-website-signup").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        // Do things on successful submission.
        $('#morgan-wade-website-signup').hide();
        $('.form-success').show();
      },
      error: function (err) {
        // Do things when submission has an error.
        $('.form-error').show();
      }
    });
  });

  // Bio Button
  $('.bio-btn, .close-bio').click(function () {
    $('.full-bio').fadeToggle();
  });

  // Tour
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/Morgan%20Wade/events?app_id=45PRESS_morgan-wade',
    method: 'GET',
    dataType: 'json',
    xhrFields: {
      withCredentials: false
    },
    error: () => {
    },
    success: data => {
      const events = $('#events');
      let html = '';
      if (data.length) {
        for (let event of data) {
          let region = event.venue.region ? event.venue.region : event.venue.country;
          let location = event.venue.city + ', ' + region;
          if (location === ', ') { location = ''; }
          if (event.venue.name.toLowerCase() === 'streaming live') { location = 'Online'; }
          html += '<div class="event" data-bit-id="' + event.id + '">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD') + '</div>';
          html += '<div class="event-venue-location">';
          html += '<div class="event-venue">' + event.venue.name + '</div>';
          html += '<div class="event-location">' + location + '</div>';
          html += '</div>';
          html += '<div class="event-links">';
          html += '<a href="' + event.url + '" target="_blank" class="btn">Tickets</a>';
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('No upcoming events.');
      }
    }
  });

  // Events Button
  $('.events-btn-more').click(function () {
    $('#events').toggleClass('all');
    $('.events-btn-more').toggle();
    $('.events-btn-less').toggle();
  });
  $('.events-btn-less').click(function () {
    $('#events').toggleClass('all');
    $('.events-btn-more').toggle();
    $('.events-btn-less').toggle();
  });

  // Animations
  AOS.init({
    duration: 500,
  });

  // Music Slider
  $('.music-slider').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    nextArrow: '<button aria-label="slider navigation" type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
    prevArrow: '<button aria-label="slider navigation" type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
  });

  $('.video-slider-small').slick({
    infinite: false,
    slidesToShow: 3.3, 
    gap: 40,
    slidesToScroll: 2,
    dots: false,
    nextArrow: '<button aria-label="slider navigation" type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
    prevArrow: '<button aria-label="slider navigation" type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
    // centerMode: true,
    centerPadding: 0,
    responsive: [{
      breakpoint: 1000,
      settings: {
        slidesToShow: 2.3
      }
    }]
  });

});